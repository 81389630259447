<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 5.99999C8.24997 5.29043 8.45125 4.59542 8.83046 3.99569C9.20968 3.39596 9.75126 2.91613 10.3923 2.61192C11.0334 2.30772 11.7476 2.19164 12.4519 2.27715C13.1563 2.36267 13.822 2.64627 14.3716 3.09503C14.9213 3.54378 15.3323 4.13927 15.557 4.81231C15.7817 5.48536 15.8108 6.20834 15.641 6.89728C15.4712 7.58623 15.1094 8.21285 14.5977 8.70437C14.0859 9.1959 13.4452 9.53214 12.75 9.67405V16.5C12.75 16.6989 12.671 16.8897 12.5303 17.0303C12.3897 17.171 12.1989 17.25 12 17.25C11.8011 17.25 11.6103 17.171 11.4697 17.0303C11.329 16.8897 11.25 16.6989 11.25 16.5V9.67405C10.4035 9.50037 9.64289 9.04005 9.09637 8.37073C8.54985 7.70142 8.25092 6.86409 8.25 5.99999ZM20.4375 13.7428C19.2881 13.0922 17.7075 12.5925 15.8653 12.2972C15.7679 12.2818 15.6683 12.2857 15.5724 12.3088C15.4765 12.3319 15.3861 12.3736 15.3063 12.4317C15.2265 12.4897 15.1589 12.5628 15.1074 12.647C15.0559 12.7311 15.0215 12.8246 15.0061 12.922C14.9907 13.0195 14.9947 13.119 15.0177 13.2149C15.0408 13.3108 15.0825 13.4013 15.1406 13.481C15.1986 13.5608 15.2718 13.6284 15.3559 13.6799C15.44 13.7314 15.5335 13.7659 15.6309 13.7812C17.2753 14.0456 18.7247 14.4966 19.7016 15.0516C20.5312 15.5156 21 16.0444 21 16.5C21 17.7525 17.5763 19.5 12 19.5C6.42375 19.5 3 17.7525 3 16.5C3 16.0444 3.46875 15.5156 4.29844 15.0478C5.27906 14.4928 6.72469 14.0419 8.36906 13.7775C8.46858 13.7645 8.56447 13.7317 8.65106 13.6809C8.73765 13.6302 8.81318 13.5626 8.87317 13.4821C8.93317 13.4017 8.9764 13.31 9.00031 13.2125C9.02423 13.1151 9.02834 13.0138 9.0124 12.9147C8.99646 12.8156 8.9608 12.7207 8.90752 12.6357C8.85425 12.5506 8.78444 12.4771 8.70225 12.4195C8.62005 12.362 8.52713 12.3215 8.429 12.3004C8.33086 12.2794 8.22951 12.2783 8.13094 12.2972C6.28875 12.5925 4.70812 13.0922 3.55875 13.7428C1.85719 14.7066 1.5 15.7744 1.5 16.5C1.5 19.4231 6.91031 21 12 21C17.0897 21 22.5 19.4231 22.5 16.5C22.5 15.7744 22.1428 14.7066 20.4375 13.7428Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup></script>
