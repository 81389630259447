export const useDynamicTruncate = (el: HTMLElement) => {
  if (!el) return;

  const parentElement = el.parentElement;
  if (!parentElement) return;

  const text = el.innerText.trim();
  const parentHeight = parentElement.clientHeight;
  el.innerText = text;

  nextTick(() => {
    truncate(el, parentElement, text);
  });

  new MutationObserver((_, observer) => {
    if (parentElement.clientHeight !== parentHeight) {
      observer.disconnect();
      truncate(el, parentElement, text);
    }
  }).observe(document, { childList: true, subtree: true });
};

const truncate = (el: HTMLElement, parentElement: HTMLElement, text: string) => {
  const paddingBottom = 16;
  const lineHeight = parseFloat(window.getComputedStyle(el).lineHeight);
  const parentHeight = parentElement.clientHeight - lineHeight
  if (lineHeight && parentHeight) {
    el.style.maxHeight = `${parentHeight}px`;
    el.style.overflow = 'hidden';
    el.style.display = 'block';
    el.style.whiteSpace = 'normal';
    el.style.marginBottom = '0px';
    el.style.paddingBottom = `${paddingBottom}px`;

    el.innerText = text;
    if (el.scrollHeight <= parentHeight) {
      return;
    }

    let start = 0;
    let end = text.length;
    let result = text;

    while (start < end) {
      const mid = Math.floor((start + end) / 2);
      const truncatedText = text.slice(0, mid).trim() + '...';
      el.innerText = truncatedText;

      if (el.scrollHeight > parentHeight) {
        end = mid; 
      } else {
        result = truncatedText; 
        start = mid + 1;
      }
    }

    el.innerText = result;
  }
};
