<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.875 13.9365V25.375C14.875 25.607 14.7828 25.8296 14.6187 25.9937C14.4546 26.1578 14.2321 26.25 14 26.25C13.7679 26.25 13.5454 26.1578 13.3813 25.9937C13.2172 25.8296 13.125 25.607 13.125 25.375V13.9365C11.5924 13.7153 10.2004 12.9218 9.22925 11.7156C8.2581 10.5095 7.77984 8.98028 7.89074 7.43573C8.00163 5.89119 8.69344 4.44597 9.82694 3.39093C10.9604 2.3359 12.4515 1.74936 14 1.74936C15.5485 1.74936 17.0396 2.3359 18.1731 3.39093C19.3066 4.44597 19.9984 5.89119 20.1093 7.43573C20.2202 8.98028 19.7419 10.5095 18.7708 11.7156C17.7996 12.9218 16.4077 13.7153 14.875 13.9365Z"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts" setup></script>
