<template>
  <div class="generic-card">
    <div class="card">
      <IconMapPinSimple v-if="props.isFeatured" class='featured'/>
      <NuxtLink v-if="props.image && props.image.length > 0" :to="cardUrl" class="card-img">
        <LqipImage :src="props.image" class="card-img-top" height="205" width="323" />
      </NuxtLink>
      <NuxtLink v-if="$slots.image" :to="cardUrl" class="card-img-slot">
        <slot name="image" />
      </NuxtLink>
      <div
        v-if="
          (props.date && props.date.length > 0) ||
          (props.category && props.category.length > 0) ||
          (props.location && props.location.length > 0) ||
          (props.department && props.department.length > 0)
        "
        class="card-header"
      >
        <div v-if="props.date && props.date.length > 0" class="date">
          {{ formatDateWithFormat(props.date, 'MM.dd.yy') }}
        </div>
        <div v-if="props.category && props.category.length > 0" class="card-category">
          <component :is="icon" v-if="icon" height="20" width="20" />
          <span>{{ props.category }}</span>
        </div>
        <div v-if="props.location && props.location.length > 0" class="card-location">
          <IconMapPinSimpleArea />
          <NuxtLink v-if="locationUrl" :to="locationUrl">{{ props.location }}</NuxtLink>
          <span v-else>{{ props.location }}</span>
        </div>
        <div
          v-if="props.department && props.department.length > 0"
          class="d-flex flex-grow-1 justify-content-end"
        >
          <div class="card-department">
            {{ props.department }}
          </div>
        </div>
      </div>
      <div class="card-body" :class="centerAlignBody? 'text-center': ''">
        <h4 v-if="props.title && props.title.length" class="card-title">
          <NuxtLink v-if="cardUrl" :to="cardUrl">{{ props.title }}</NuxtLink>
          <span v-else>{{ props.title }}</span>
        </h4>

        <div
          v-if="props.description && props.description.length > 0 && !isEmpty(props.description)"
          ref="customElementText"
          class="card-text"
        >
          <span v-html="props.description"> </span>
        </div>
        <div
          v-else-if="props.customElementContent && props.customElementContent.length > 0"
          ref="customElementText"
          class="card-text"
        >
          <span v-html="props.customElementContent"> </span>
        </div>
        <div
          v-else-if="props.content && !isEmpty(props.content)"
          ref="truncateText"
          class="card-text"
        >
          <span v-html="props.content" />
        </div>
        <slot name="body" />
      </div>
      <div v-if="props.ctaLink && props.ctaLink.length > 0" class="card-cta">
        <NuxtLink :class="`btn btn-manatt-black`" :to="props.ctaLink">
          {{ props.ctaLabel ?? 'Read More' }}
        </NuxtLink>
        <slot name="ctaLeft" />
      </div>
      <slot name="cta" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'

const props = defineProps<{
  image?: string
  date?: string
  category?: string
  title?: string
  description?: string
  ctaLink?: string
  ctaLabel?: string
  location?: string
  locationUrl?: string
  department?: string
  content?: string
  customElementContent?: string
  cardUrl?: string
  centerAlignBody?: boolean
  isFeatured?: boolean
}>()

defineEmits(['HeaderClick', 'ImageClick'])
const truncateText = ref<HTMLElement | null>(null)
const customElementText = ref<HTMLElement | null>(null)
//const truncate = stripHtmlTags(props.description ?? '').replace(/&nbsp;|\u00A0/g, ' ')
const pageStore = usePageStore()
const icon = computed(() => {
  const categoryIcon = `Icon${capitalize(props.category?.replaceAll('_', '').replaceAll(' ', ''))}`
  if (!props.category || props.category.length === 0) return null
  return defineAsyncComponent(() =>
    import(`~/components/icons/${categoryIcon}.vue`).catch(() => ({
      render() {
        return null
      }
    }))
  )
})

const themeColor = pageStore.getThemeColor()
const btnColor = themeColor == 'gold' ? 'black' : themeColor

const debouncedTruncate = debounce(
  (el: HTMLElement) => {
    useDynamicTruncate(el)
  },
  300,
  { leading: true }
)
onMounted(() => {
  if (truncateText?.value?.children[0]) {
    debouncedTruncate(truncateText.value.children[0] as HTMLElement)
  }
  if (customElementText?.value?.children[0]) {
    debouncedTruncate(customElementText.value.children[0] as HTMLElement)
  }
})
</script>
